$(document).ready(function(){
    'use strict';

    function init(){
        $('select.select-control').selectpicker({
            size: 4,
            width: '100%'
        });
        
        jQuery('img.svg').each(function(i) {
            var $img = jQuery(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');

            jQuery.get(imgURL, function(data) {
                // Get the SVG tag, ignore the rest
                var $svg = jQuery(data).find('svg');

                // Add replaced image's ID to the new SVG
                if (typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                // Add replaced image's classes to the new SVG
                if (typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass + ' replaced-svg');
                }

                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');

                // Replace image with new SVG
                $img.replaceWith($svg);

            }, 'xml');

        });
        setTimeout(func, 500);
    }init();

    function inputScale() {
        $.fn.textWidth = function(text, font) {
            if (!$.fn.textWidth.fakeEl) $.fn.textWidth.fakeEl = $('<span>').hide().appendTo(document.body);
            $.fn.textWidth.fakeEl.text(text || this.val() || this.text() || this.attr('placeholder')).css('font', font || this.css('font'));
            return $.fn.textWidth.fakeEl.width();
        };

        $('.width-dynamic').on('input', function() {
            var inputWidth = $(this).textWidth() + 30;
            $(this).css({
                width: inputWidth
            })
        }).trigger('input');

        function inputWidth(elem, minW, maxW) {
            elem = $(this);
            console.log(elem)
        }

        var targetElem = $('.width-dynamic');
        inputWidth(targetElem);
    }

    function func() {

        // other wrap
        $('.has-other').each(function(){
            var t = $(this),
                s = t.find('select'),
                o = t.find('.other-wrap');

            o.hide();

            s.change(function(){
                var v = $(this).val();
                if(v == 'other'){
                    o.show();
                }else{
                    o.hide();
                }
            })
        })

        //input number
        $("body").find('input.number').keydown(function(e) {
            // console.log(e.keyCode);
            if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
                (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
                (e.keyCode >= 35 && e.keyCode <= 40)) {
                return;
            }
            if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                e.preventDefault();
            }
        });
        $("body").find('input.phone').keydown(function(e) {
            // console.log(e.keyCode);
            if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 187, 188, 189, 190]) !== -1 ||
                (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
                (e.keyCode >= 35 && e.keyCode <= 40)) {
                return;
            }
            if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                e.preventDefault();
            }
        });

        $('.title-editable').each(function(){
            var t = $(this),
                tt = t.find('.title span'),
                ie = t.find('.input-edit'),
                be = t.find('.btn-edit'),
                bs = t.find('.btn-save'),
                bc = t.find('.btn-cancel');

            ie.val(tt.text());
            inputScale();

            be.click(function(){ t.addClass('editing') });
            bc.click(function(){ t.removeClass('editing') });
            bs.click(function() {
                var isi = ie.val();
                tt.text(isi);
                t.removeClass('editing');
            });
        })
        

        // DATEPICKER
        $('.datepicker').each(function(){
            var t = $(this);
            t.find('input').datepicker({
                format: 'dd M yyyy',
                todayHighlight: true,
                autoclose: true
            });
        });

        // UPLOAD IMAGE
        var inputimgs = $('.file-control.file-images');
        inputimgs.each(function(){
            var t = $(this),
                input = t.find('input[type="file"]'),
                max = input.data('max'),
                preview = t.find('.preview-images'),
                btnDel = t.find('.file-delete');

            input.change(function(){
                if(this.files && this.files[0]) {
                    var fl = (this.files.length);
                    if(fl <= max){
                        preview.find('.thumb').remove();
                        $(this.files).each(function() {
                            var reader = new FileReader();
                            reader.readAsDataURL(this);
                            reader.onload = function(e) {
                                preview.append('<div class="thumb"><div class="img"><img src="'+ e.target.result +'"></div></div>');                    
                            }
                        })
                        t.addClass('has-file');
                        if(fl == max){
                            t.addClass('disabled');
                        }
                    }else{
                        alert('To many files selected')
                    }
                }

                // get inputfile multiple values
                // var names = [];
                // for (var i = 0; i < $(this).get(0).files.length; ++i) {
                //     names.push($(this).get(0).files[i].name);
                // }
                // console.log(names);
            })

            btnDel.click(function(){
                input.val('');
                preview.find('.thumb').remove();
                t.removeClass('has-file');
                t.removeClass('disabled');
            })
        })

        //SORTABLE
        $( "#cardList1, #cardList2, #cardList3, #cardList4, #cardList5, #cardList6" ).sortable({
            connectWith: ".card-list"
        }).disableSelection();

    }

    
});